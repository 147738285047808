import { api, currentCreche } from '../api'

const RegistrationRepository = {
    index (query = null) {
        return api().$get(`/${currentCreche().uuid}/registrations`, query)
    },

    allBalances () {
        return api().$get(`/${currentCreche().uuid}/registrations/billing-balance`)
    },

    exportAllBalances () {
        return api().$post(`/${currentCreche().uuid}/registrations/billing-balance/export`)
    },

    show (uuid) {
        return api().$get(`/${currentCreche().uuid}/registrations/${uuid}`)
    },

    create (payload) {
        return api().$post(`/${currentCreche().uuid}/registrations`, payload)
    },

    update (uuid, payload, crecheUuid = null) {
        return api().$put(`/${crecheUuid || currentCreche().uuid}/registrations/${uuid}`, payload)
    },

    delete (uuid, crecheUuid = null) {
        return api().$delete(`/${crecheUuid || currentCreche().uuid}/registrations/${uuid}`)
    },

    forceDelete (uuid, crecheUuid = null) {
        return api().$delete(`/${crecheUuid || currentCreche().uuid}/registrations/${uuid}`, { force: true })
    },

    restore (uuid) {
        return api().$post(`/${currentCreche().uuid}/registrations/${uuid}/restore`)
    },

    getLogs (uuid, query = {}) {
        return api().$get(`/${currentCreche().uuid}/registrations/${uuid}/activity-logs`, query)
    },

    exportLogs (uuid, query = {}) {
        return api().$get(`/${currentCreche().uuid}/registrations/${uuid}/activity-logs/export`, query, { download: true })
    },

    quota (uuid, query = {}, crecheUuid = null) {
        return api().$get(`/${crecheUuid || currentCreche().uuid}/registrations/${uuid}/quota`, query)
    },

    balance (uuid) {
        return api().$get(`/${currentCreche().uuid}/registrations/${uuid}/billing-balance`)
    },

    invoiceDeposit (registrationUuid) {
        return api().$post(`/${currentCreche().uuid}/registrations/${registrationUuid}/invoice-deposit`)
    },

    downloadFiscalDocument (registrationUuid, payload) {
        return api().$get(`/${currentCreche().uuid}/registrations/${registrationUuid}/fiscal-document`, payload, {
            responseType: 'blob'
        })
    },

    export (payload) {
        return api().$post(`/${currentCreche().uuid}/registrations/export`, payload, { responseType: 'blob' })
    },

    exportQrCodes (registrationUuids) {
        return api().$post(`/${currentCreche().uuid}/registrations/qr-codes`, { registration_uuids: registrationUuids }, { responseType: 'blob' })
    },

    acceptWaitingListNotification (registrationUuid) {
        return api().$post(`/${currentCreche().uuid}/registrations/${registrationUuid}/waiting-list/accept`)
    }
}

export default RegistrationRepository
